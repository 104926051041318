// Toolkit Banner Mixin
@mixin sbf-banner() {
  background: $sbf-blue;
  width: 100%;
  color: white;
  padding: 28px;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  z-index: 20000;
  transform: translate(0, -100%);
  transition:
    transform 200ms cubic-bezier(0.58, 0.09, 0.67, 1.33),
    background 100ms linear;
  font-family: $sansSerifFamily;

  &.loading {
    background: $sbf-blue;
    transform: translate(0, 0);
  }

  &.error {
    background: $sbf-red !important;
    color: white;

    .button.confirm {
      color: $sbf-red;
    }
  }

  &.warning {
    background: $sbf-palette-orange-500;
  }

  &.new_error {
    animation: wiggle-with-it 200ms ease-out;
  }

  button {
    @include sbf-button(
      $background-color: transparent,
            $border-color: $sbf-white,
            $color: $sbf-white,
            $hover-background: transparent,
            $hover-border: $sbf-white
        );

    padding: 6px 17px;
    min-width: 40px;
    margin-left: 8px;
    margin-bottom: 0;
    letter-spacing: 0;
  }

  .button.confirm {
    min-width: 88px;
    background: white;
    border-color: rgba(255, 255, 255, 0.5);
    color: $sbf-blue;
  }

  .button.confirm + .button.close {
    font-weight: 400;
  }

  .banner__controls {
    @media (max-width: 600px) {
      display: block;
      padding: 0.5em 0;
    }
  }

  &.long {
    .button {
      display: block;
      margin: 12px auto 0;
      padding: 8px 24px;
    }
  }

  a {
    text-decoration: none;
    color: white;
    border-bottom: 1px solid white;
    padding: 2px 0;

    &:hover {
      color: white;
      border-color: rgba(255, 255, 255, 0.7);
    }
  }
}
