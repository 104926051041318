$overlayPadding: 35px;

.overlays-container {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $medium) {
    overflow-y: auto;
  }

  li {
    .plus {
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translate(0, -50%);
      background: $sbf-light-green;
      border: 4px $sbf-light-green;
      border-radius: 50%;
      width: 23px;
      height: 23px;
    }
  }
}

.overlay {
  position: absolute;
  min-width: 200px;
  background: white;
  color: #222;
  z-index: 5;
  display: none;
  font-family: $sansSerifFamily;

  &.fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 500px;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    background: $sbf-white;
    background-color: $sbf-white;
    color: $sbf-black;
    padding: $overlayPadding;
    padding-bottom: 64px;
    z-index: -1;
    transform: translateY(20%);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;

    &::after {
      display: none;
    }

    & + .overlay__mask {
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      height: 100%;
      width: 100%;
      z-index: 10;
    }

    a {
      text-decoration: none;
      // not entirely sure what this is used for
      // color: white;
      // border-color: rgba(255,255,255,0.3);
    }

    h1 {
      font-size: 1.2em;
      color: $sbf-white;
      letter-spacing: -0.5px;
      margin: 0.67em 0;
    }

    @media (max-width: ($medium - 1)) {
      bottom: 0;
      overflow-y: auto;
    }

    @media (min-width: $medium) {
      margin-top: 80px;

      &.overlay--large {
        margin-top: 115px;
      }

      max-width: 80%;

      h1 {
        font-size: 1.5em;
      }
    }

    &.is-blocked {
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: -20px;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 12;

        @media (max-width: $medium) {
          height: 1000%;
        }
      }
    }
  }

  &.fullscreen.no-padding {
    padding: 0;
  }

  &.overlay--large {
    width: 780px;
    padding: 0 24px 48px;

    .overlay__heading,
    .overlay__dark {
      padding: 20px 34px;
      margin: 0 -24px 35px -24px;
    }

    @media (min-width: $small) {
      padding: 0 40px 48px;

      .overlay__heading,
      .overlay__dark {
        margin: 0 -40px 35px -40px;
      }
    }

    @media (min-width: $medium) {
      padding: 48px;
      padding-top: 0;

      .overlay__heading,
      .overlay__dark {
        padding: $overlayPadding 48px;
        margin: -$overlayPadding -48px $overlayPadding;
      }
    }
  }

  &.overlay--xlarge {
    width: 900px;
    padding: 0 24px 48px;

    .overlay__heading,
    .overlay__dark {
      padding: 20px 34px;
      margin: 0 -24px 35px -24px;
    }

    @media (min-width: $small) {
      padding: 0 34px 48px;

      .overlay__heading,
      .overlay__dark {
        margin: 0 -34px 35px -34px;
      }
    }

    @media (min-width: $medium) {
      padding: 48px;
      padding-top: 0;

      .overlay__heading,
      .overlay__dark {
        padding: $overlayPadding 48px;
        margin: 0 -48px $overlayPadding;
      }
    }
  }

  .white-list {
    background: white;
  }

  &::after {
    content: '';
    position: absolute;
    top: -9px;
    right: 6%;
    margin-left: -5px;
    border-right: 10px solid white;
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-color: white;
    transform: rotate(-45deg);
    z-index: -1;
  }

  .inline-input {
    width: 100%;
  }

  .empty {
    color: #333;
  }

  .button {
    @include sbf-button();
    // TODO: this is probably overly specfic
    margin: 16px 8px;
  }

  .button--blue {
    @include sbf-button($btnType: "sbf-blue");
  }

  .center {
    text-align: center;
  }
}

.overlay__close {
  display: block;

  @media (min-width: $medium) {
    display: none;
  }
}

.overlay hr {
  height: 24px;
  width: 100%;
  border: none;
  margin: 20px 0;
  margin-right: 8px;
  padding: 0;
  transform: scale(0.6, 0.6);
}

.overlay__close {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10005;
  color: white;
  border-bottom: none;
  text-align: center;
  padding: 6px 12px;
  font-weight: bold;
  font-size: 22px;
  background: rgba(0, 0, 0, 0.7);
  line-height: 1;
  height: 40px;
  width: 40px;
  border-bottom-left-radius: 16px;
  text-decoration: none;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

.overlay--shown {
  display: block;
}

.overlay.fullscreen.overlay--shown {
  transform: translateY(0);
  opacity: 1;
  z-index: 11;
}

.overlay__heading,
.overlay__dark {
  background: $sbf-black;
  margin: 0 - $overlayPadding;
  margin-bottom: $overlayPadding;
  padding: $overlayPadding;
  text-align: center;

  @media (max-width: $medium - 1) {
    margin-bottom: $overlayPadding;
  }
}

.overlay--large .overlay__heading,
.overlay--large .overlay__dark {
  margin: 0 -24px;
  margin-bottom: $overlayPadding;

  @media (min-width: $small) {
    margin: 0 -40px $overlayPadding;
  }
}

.overlay.fullscreen.no-padding .overlay__heading {
  margin: 0 0 $overlayPadding 0;
  padding: $overlayPadding;
}

.overlays-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.75;
  z-index: 1000; // TODO: come up with a real plan
}

.overlay__content {
  p {
    margin-bottom: 14px;
  }
}
