@mixin old-footer-styles() {
  .tk-old-footer-nav {
    font-family: $sansSerifFamily;
    line-height: 1.5;
    font-style: normal;
    display: none;

    a {
      color: inherit;
      text-decoration: none;
      border-bottom: none;
    }

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    ul > li > ul {
      list-style: none;
      padding: 8px 28px;

      > li {
        border: none;
        padding: 0;

        a {
          display: block;
          padding: 5px;

          &:hover {
            background-color: rgba(white, 0.2);
            color: #0C85CD;
          }
        }
      }
    }

    .icon--small {
      height: 12px;
      width: 12px;
      margin: 0 0 0 4px;
    }

    @media (max-width: $medium - 1px) {
      display: block;
      width: 100%;
      background: white;

      ul,
      li {
        padding: 0;
        margin: 0;
      }

      ul {
        margin-bottom: 0;
        list-style-type: none;
      }

      li {
        background: #E8F0F5;
        padding: 12px;
        border-top: 1px solid #D1E0EC;
        font-size: 1em;
        line-height: 1.35em;
        font-weight: 500;
        font-family: $serifFamily;
      }

      a:link,
      a:visited {
        text-decoration: none;
        border-bottom: none;
        color: $sbf-blue;
      }

      .old-footer__links:link,
      .old-footer__links:visited {
        color: $sbf-blue;
      }

      .icon-arrow.icon-arrow {
        height: 20px;
        width: 20px;
        display: inline-block;
        position: absolute;
        top: 5px;
        right: 6px;
      }

      .old-footer__links {
        font-size: 1em;
        line-height: 1.35em;
        font-weight: bold;
        padding: 8px 0 8px 16px;
        display: inline-block;
        color: $sbf-blue;

        .icon-arrow {
          transform: rotate(180deg);
          transform-origin: center;
          display: inline-block;
        }
      }

      .old-footer__links.emails,
      .old-footer__links.links_logout {
        padding-left: 0;

        &::before {
          content: ' \00b7\ '; /* middot */
        }
      }

      .to-top {
        // top: -11px;
        float: right;
        padding-right: 26px;
        text-transform: uppercase;
      }
    }
  }
}

@mixin main-footer-styles() {
  .tk-footer {
    color: white;
    font-family: $sansSerifFamily;
    background-color: $sbf-green;
    padding-bottom: 36px;
    text-align: center;
    font-size: 16px;
    line-height: 1.618;
    border-top: $sbf-gold 3px solid;
    padding-top: 32px;

    @media (min-width: $large) {
      padding-top: 16px;
    }

    .inner {
      width: 100%;
      max-width: 940px;
      padding-right: 0;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: baseline;
    }

    a {
      color: inherit;
      font-weight: 500;
      text-decoration: none;
      border-bottom: none;
    }

    a:hover {
      color: lighten($sbf-green, 40%);

      > .icon {
        opacity: 0.55;
      }
    }

    .footer--fine-print {
      width: 100%;
      text-align: center;
      margin: 0 auto 23px;
    }

    .home {
      position: relative;
      display: inline-block;
      padding-bottom: 2px;
      min-width: 0;
      top: 18px; // half of the logo height
      margin: 0 24px 18px;

      &:hover {
        opacity: 1;
      }
    }

    svg {
      fill: currentColor;
    }

    .icon.footer--logo {
      color: #92AB77;
      width: 52px;
      height: 52px;

      @media (max-width: $medium) {
        width: 40px;
        height: 40px;
      }
    }

    address {
      width: auto;
      font-size: 0.9em;
      font-style: normal;
      margin-bottom: 0;
      flex-basis: 100%;
      width: 100%;
    }

    .address-line::before {
      content: ' \00b7\ ';
      color: $sbf-light-green;
    }

    .address-line:first-child::before {
      content: '';
    }

    .address-line:last-child::before {
      content: ' \00B7\ ';
      color: $sbf-light-green;
    }

    address a {
      font-weight: normal;
    }

    address a,
    .footer--fine-print a {
      border-bottom: $sbf-light-green 1px dotted;
      font-weight: 400;
    }

    .footer--nav,
    .footer__interact {
      text-align: right;
      height: 40px;
      font-size: 0.92em;
      margin-left: 0;
      border-bottom: none;
      box-shadow: none;
      list-style: none;
      font-family: $serifFamily;
      flex: 4 1 40%;
      margin: 0;
      padding: 0;
    }

    .footer--nav a::before {
      content: ' \00B7\ ';
      color: #C5D2B1;
    }

    .footer--nav li:first-child a::before {
      content: '';
    }

    .footer--nav li {
      display: inline-block;
    }

    .footer__interact span,
    a {
      display: inline-block;
    }

    .footer__interact a {
      margin-right: 14px;

      &.sbf-on-fb,
      &.twitter,
      &.linkedin,
      &.instagram,
      &.tiktok {
        vertical-align: middle;
      }

      &.email-link {
        margin-right: 6px;
      }
    }

    .dot {
      color: $sbf-light-green;
    }

    .footer--fine-print {
      display: block;
      color: #C5D2B1;
      font-size: 0.62em;
      line-height: 1.618;
    }

    .sbf-on-fb .icon {
      width: 24px;
      height: 24px;
      color: white;
    }

    .twitter .icon {
      width: 24px;
      height: 24px;
      color: white;
    }

    .linkedin .icon {
      width: 24px;
      height: 24px;
      color: white;
    }

    .instagram .icon {
      width: 24px;
      height: 24px;
      color: white;
    }

    .tiktok .icon {
      width: 24px;
      height: 24px;
      color: white;
    }

    .footer__interact {
      text-transform: none;
      text-align: left;
      color: #FFF;
      letter-spacing: 0;
      font-weight: 600;
    }

    .footer__interact .email-link::after {
      content: ' \00B7 ';
      padding-left: 5px;
      color: #C5D2B1;
    }

    @media (max-width: 899px) {
      .footer__interact .email-link {
        display: none;
      }
    }

    @media (max-width: $large) {
      padding-bottom: 0;

      .inner {
        max-width: 550px;
      }

      .footer--nav {
        font-size: 0.8em;
      }

      .footer--nav,
      .footer__interact {
        text-align: center;
        flex-basis: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.05);
      }

      .footer__interact {
        padding: 6px 0 3px;
        height: auto;
      }

      .home {
        order: 2;
      }

      address {
        padding: 8px 16px;
        order: 3;
      }

      .footer--fine-print {
        width: 100%;
        padding: 8px 16px;
        order: 4;
      }
    }

    @media (max-width: 759px) {
      .address-line {
        display: block;

        &::before {
          display: none;
        }
      }

      .footer--fine-print span {
        // IE can't seem to align this properly without the span (due to a bug)
        display: block;
        margin: 0 auto;
        width: 300px;
      }
    }

    @media (max-width: 390px) {
      .footer--nav {
        font-size: 9.5px;
      }
    }

    @media (max-width: 360px) {
      .footer--nav {
        font-size: 9px;
      }
    }

    @media (max-width: 320px) {
      .footer--nav {
        font-size: 8px;
      }
    }

    .tablet-only {
      display: none;

      @media (min-width: 760px) { display: inline; } // (min-width: 768px)

      @media (min-width: 900px) { display: none; }
    }
  }
}
