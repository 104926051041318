$svg-icon-path: 'icons/';

@import "~sbf-design-system/scss/base";
@import "~sbf-design-system/scss/utility_classes";
@import "~sbf-design-system/scss/custom-normalize";
@import "~sbf-design-system/scss/mixins/buttons";
@import "~sbf-design-system-component/overlays/overlay";
@import "~sbf-design-system-component/forms/forms";
@import 'privacy_consent_banner/privacy_consent_banner';

@include old-footer-styles();
@include main-footer-styles();

[role=main] {
  min-height: calc(100% - 260px);
}

@media (prefers-reduced-motion: reduce) {
  video {
    display: none;
  }
}

// Website-Ruby Components

$btnColors: (
  "dark-green": #206A3F,
  "extra-dark-green": #3E5722,
  "green": #69903E,
  "lime-green": #1F9F5D,
  "red": #CD2617,
  "light-blue": #3A89C6,
  "black": $sbf-black,
  "purple": #552562,
  "white": #FFF,
  "yellow": #E8AE34,
  "orange": #E58423,
  "blue": #3687C8
);

a {
  @include link($sbf-blue);

  text-decoration: none;
  border-bottom: none;
}

.no-underline {
  border-bottom: 0;
}

.button {
  padding: 8px 16px;
  background: white;
  display: inline-block;
  border: 2px solid $sbf-blue;
  border-radius: 3px;
  color: $sbf-blue;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  transition:
    background 50ms linear,
    border-color 50ms linear,
    color 50ms linear,
    opacity 50ms linear;
  white-space: nowrap;
  font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background-color: $sbf-blue;
    border-color: $sbf-blue;
    color: $sbf-white;
    border-bottom-width: 2px;
  }

  &.fill {
    background: $sbf-blue;
    color: white;
    border: 0;

    &:hover,
    &:focus {
      background: darken($sbf-blue, 10%);
      border-color: darken($sbf-blue, 10%);
    }
  }

  &.small {
    font-size: 0.8em;
    padding: 4px 8px;
    text-align: center;
  }

  &--large {
    font-size: 1.3em;
    min-width: 285px;
    text-align: center;
    padding: 14px 18px;

    @media (max-width: $small) {
      max-width: 100%;
      font-size: 1em;
    }
  }
}

.unstyled-button {
  display: inline-block;
  background: none;
  border: none;
  font-weight: 400;
  padding: 0;
  margin: 0;
  text-align: left;
}

[disabled] {
  opacity: 0.3;
  pointer-events: none;
}

.small {
  font-size: 0.8em;

  &.button {
    padding: 4px 8px;
  }
}

@each $colorName, $color in $btnColors {
  .button--#{$colorName} {
    border-color: $color;
    color: $color;
    background: transparent;

    &:hover,
    &:focus {
      border-color: darken($color, 30%);
      color: darken($color, 30%);
      background-color: transparent;
    }

    &.fill {
      background: $color;
      border: none;

      &:hover,
      &:focus {
        background: darken($color, 10%);
        color: white;
      }
    }
  }
}

.round {
  border-radius: 50%;
}

.link {
  background: none;
  border: none;
  color: $sbf-blue;

  &:hover {
    color: darken($sbf-blue, 20%);
    border-bottom: 1px solid darken($sbf-blue, 20%);
  }
}

.white-links {
  a {
    &:link,
    &:visited {
      color: white;
      border-color: white;
      border-color: rgba(255, 255, 255, 0.3);
    }

    &:hover,
    &:active,
    &:focus {
      color: white;
      border-color: rgba(255, 255, 255, 0.7);
    }
  }
}

.green-links {
  a {
    &:link,
    &:visited {
      color: $sbf-green;
      border-color: lighten($sbf-green, 10%);
    }

    &:hover,
    &:active,
    &:focus {
      color: darken($sbf-green, 10%);
      border-color: $sbf-green;
    }
  }
}

.black-links {
  $black: map-get($btnColors, "black");

  a {
    &:link,
    &:visited {
      color: $black;
      border-color: lighten($black, 50%);
    }

    &:hover,
    &:active,
    &:focus {
      color: lighten($black, 15%);
      border-color: lighten($black, 70%);
    }
  }
}

[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);

  + label {
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    margin-left: 40px;

    &::before {
      box-sizing: border-box;
      content: '\2713'; // A CSS escaped ✓
      height: 22px;
      width: 22px;
      border: 2px solid #6A6A6A;
      display: inline-block;
      margin: 0 6px;
      background: transparent;
      position: absolute;
      top: 0;
      left: -40px;
      font-size: 1.5rem;
      line-height: 0.8;
      color: transparent;
    }
  }

  &:checked + label {
    &::before {
      color: #8BB55C;
    }
  }
}

// TODO: correct syntax to checkboxes--light and find/replace all usage instances
.checkboxes__light {
  [type="checkbox"] {
    +label {
      &::before {
        background: white;
      }
    }

    &:checked + label {
      &::before {
        color: $sbf-green;
      }
    }

    &[disabled] + label {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.checkboxes--dark {
  [type="checkbox"] {
    &:checked + label {
      &::before {
        background: #222;
        color: #188743;
      }
    }

    &[disabled] + label {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

// This clearfix is required for atleast the non-design-system ported header and there are also some references to it in the seed content files. -Jeremy B.
.cf::before,
.cf::after {
  content: " ";
  display: table;
}

.cf::after {
  clear: both;
}

.clear-both {
  clear: both;
}

.hidden {
  display: none !important;
}

// The banner has been ported to the design-system. We should be able to verify and delete this at some point in the future.
.banner {
  background: $sbf-blue;
  width: 100%;
  color: white;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  font-weight: 600;
  z-index: 2000;
  transform: translate(0, -100%);
  transition:
    transform 200ms cubic-bezier(0.58, 0.09, 0.67, 1.33) 0s,
    background 100ms linear 0s;

  .button {
    margin: 8px;
    padding: 4px 16px;
    font-size: 0.9em;
  }
}

.banner.show {
  transform: translate(0, 0);
}

.banner.dark {
  background: $sbf-black;
}

.mypage--banner {
  @include sbf-banner();

  p {
    @include sbf-body('small-sans');

    max-width: 80ch;
    margin: 0 auto 12px auto;
  }

  &.warning {
    padding: 28px 20px;
    text-align: center;
  }
}

$mediaObjectMargin: 16px;

// http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/

.media {
  margin: $mediaObjectMargin;
}

.media,
.media__body {
  overflow: hidden;
  _overflow: visible;
  zoom: 1;
  text-align: left;
}

.media .media__img {
  float: left;
  margin-right: $mediaObjectMargin;
}

.media .media__img img {
  display: block;
}

.media .imgExt {
  float: right;
  margin-left: $mediaObjectMargin;
}

// http://csswizardry.com/2013/05/the-flag-object/
.flag {
  display: table;
  width: auto;
  margin: $mediaObjectMargin;
}

.flag__image,
.flag__body {
  display: table-cell;
  vertical-align: middle;

  .flag--top & {
    vertical-align: top;
  }

  .flag--bottom & {
    vertical-align: bottom;
  }
}

.flag__image {
  padding-right: $mediaObjectMargin;

  > img {
    display: block;
  }

  .flag--rev & {
    padding-right: 0;
    padding-left: $mediaObjectMargin;
  }
}

img.flag__image {
  padding-right: 0;
  width: 79px;
  height: 80px;
}

.flag__body {
  width: 100%;
}

/* hr */
.gray-divider {
  height: 24px;
  width: 100%;
  border: none;
  margin: 20px 0;
  padding: 0;
  transform: scale(0.6, 0.6);
  background: url('images/gray-divider.jpg') center center no-repeat;
}

/* hr */
.sb-hr {
  max-width: 675px;
}

// The spinner mixin has been ported to the design-system. We should be able to verify and delete this at some point in the future.
.spinner {
  display: block;
  margin: 100px auto;
  background: transparent;
  border-radius: 100%;
  animation: spin 1s infinite steps(8);
  transform-origin: 50% 50%;

  @include spinner();
}

.spinner-after {
  &::after {
    content: '';
    display: inline-block;
    animation: spin 1s infinite steps(8);
    transform-origin: 50% 50%;

    @include spinner(8px, 10, 3px);
  }
}

.spinner-before {
  &::before {
    content: '';
    display: inline-block;
    animation: spin 1s infinite steps(8);
    transform-origin: 50% 50%;

    @include spinner(4px, 5, 2px);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;

  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 20px 0;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed,
.responsive-video div.sbf-ce-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mobile-hide {
  @media (max-width: $small) {
    display: none !important;
  }
}

.tablet-hide {
  @media (min-width: $small) and (max-width: $large) {
    display: none !important;
  }
}

.tablet-show {
  display: none !important;

  @media (min-width: $small) and (max-width: $large) {
    display: block !important;
  }
}

// Website-Ruby Header

// Website-Ruby Footer

.inner > * {
  max-width: $x-large;
  min-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.doe-donate--main {
  overflow-x: hidden;
}

[role="main"] {
  padding: 1px 7px 0;

  @media (min-width: $medium) {
    padding: 24px 8px 0;
  }
}

.emph {
  font-style: italic;
}

.strong {
  font-weight: bold;
}

.upper {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.icon {
  width: 26px;
  height: 26px;
  fill: currentColor;
  display: inline-block;
  text-indent: -999em;
}

.icon--blue {
  color: $sbf-blue;
}

.icon--green {
  color: $sbf-green;
}

.visually-hide {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.go-left {
  float: left;
}

.go-right {
  float: right;
}

.error {
  color: $sbf-red;
  display: block;
}

.feedback {
  color: $sbf-dark-green;
  padding-bottom: 8px;
  display: block;
}

.label--hidden {
  position: absolute;
  text-indent: 150%;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.download-csv {
  display: inline;
}

.italics {
  font-style: italic;
  font-family: $serifFamily;
}

.primary-nav {
  nav {
    div {
      min-height: 68px;

      optgroup {
        option {
          text-indent: 16px;
        }
      }
    }
  }
}

@keyframes wiggle-with-it {
  from,
  to {
    transform: translateY(0);
  }

  33% {
    transform: skew(40deg) translateX(20px);
  }

  66% {
    transform: skew(-40deg) translateX(-20px);
  }
}

.list--inline li {
  list-style: none;
  display: inline-block;
}

.tablet-only {
  display: none;

  @media (min-width: 760px) { display: inline; } // (min-width: 768px)

  @media (min-width: 900px) { display: none; }
}

// TK override for Buttons
.overlay-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $medium) {
    display: block;
  }

  .save {
    background-color: $sbf-blue;
    border-color: $sbf-blue;
    color: $sbf-white;
    order: 1;
    margin: 16px 0 8px;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($sbf-blue, 10);
      border-color: darken($sbf-blue, 10);
    }
  }

  .cancel {
    background-color: $sbf-white;
    border-color: $sbf-blue;
    color: $sbf-blue;
    order: 2;
    margin-left: 0;
    margin-right: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: $sbf-blue;
      border-color: $sbf-blue;
      color: $sbf-white;
    }

    @media (min-width: $medium) {
      margin-right: 8px;
    }
  }
}

// Header
$primary-nav-bp: 1019px;
$headerHeight: 68px;

/* Between 760 - 1019bp dropdown nav hard to select from. TP-28893 */

@media (max-width: $medium - 1) {
  /* body */
  .nav-open {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.mobile-new-donation-toggle,
.mobile-doe-toggle {
  top: 4px;
  right: 44px;
  color: white;

  svg {
    width: 30px;
    height: 30px;
    top: -2px;
  }

  &:hover,
  &:focus,
  &:active {
    color: white;
  }

  @media (min-width: $medium) {
    display: none;
  }
}

.doe-nav-toggle-1 {
  margin-left: 4px;
  min-width: 30px;
}

.mobile-new-donation-toggle {
  margin-left: 10px;
  min-width: 30px;
}

header {
  background: $sbf-green;
  color: white;
  width: 100%;
  border-bottom: 3px solid $sbf-gold;
  z-index: 50;

  a {
    border-bottom: none;
    display: inline-block;
    padding: 4px 0;
  }

  .header__search {
    flex-grow: 1;
    margin-right: 78px;

    input {
      -webkit-appearance: none;
      border-radius: 8px;
      margin: 16px 48px 0 24px;
      padding: 8px 14px;
      font-size: 13px;
      top: -11px;
      right: -67px;
      color: #444;
      width: 100%;
      border: none;
      border-bottom: 1px solid #DDD;
      box-shadow: inset 0 1px 2px #CCC;
      background: white;
      opacity: 1;
      transition: all 300ms ease-out;
      float: right;

      &:focus {
        border-color: $sbf-dark-green;

        & + button {
          color: $sbf-dark-green;
        }
      }
    }

    .search_button {
      transition: transform 200ms ease-out;
    }

    .search_button .icon {
      height: 28px;
      width: 28px;
      transform: scale(0.57);
      transform-origin: center;
      transition: transform 200ms ease-out;
    }

    @media (max-width: $medium - 1) {
      input {
        opacity: 0;
        width: 0;
      }

      .search_button {
        transform: translate(17px);

        .icon {
          color: white;
          transform: none;
        }
      }
    }
  }

  > * {
    padding: 16px 16px 6px;
    min-height: $headerHeight;
  }

  .donate-link {
    display: none;
  }

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $sbf-green;
  }

  @media (min-width: $medium) {
    .header__search {
      max-width: 225px;
      margin-right: 18px;

      input {
        margin-left: 20px;
      }
    }

    .donate-link {
      display: block;
      margin-left: 24px;
      line-height: 2.6;
      align-self: baseline;
      height: 34px;
      color: white;
      letter-spacing: 0.05em;
    }
  }

  .icon--small {
    width: 12px;
    height: 12px;
    margin: 0 0 0 4px;
  }
}

.header-flex {
  display: flex;

  .logo-img {
    position: relative;
    width: 37px;
    height: 33px;
  }
}

.search_button {
  background: none;
  color: #A6A6A6;
  position: absolute;
  right: -10px;
  top: 8px;
  border: 0;

  .icon {
    height: 16px;
    width: 16px;
  }

  &:focus,
  &:hover,
  &:active {
    color: $sbf-dark-green;
  }
}

.fb-nav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 12px;
  font-size: 18px;

  @media (max-width: $medium - 1) {
    display: none;
    position: fixed;
    overflow: scroll;
    width: 100%;
    height: calc(100% - #{$headerHeight});
    background: #E7EDF6;
    top: $headerHeight;
    padding: 0;
    z-index: 1000;
    text-align: center;

    &.visible {
      display: block;
    }

    &::before {
      position: fixed;
      content: '';
      height: 1px;
      width: 1px;
      top: $headerHeight - 2px;
      right: 20px;
      border-top: 10px solid #69903E;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }
}

.text-link {
  display: inline-block;
  padding: 0;
  line-height: 1;
  border-bottom: 1px solid white;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  transition: border-bottom-color 100ms;
  margin-right: 5px;
  font-size: 18px;

  &:hover {
    border-bottom-color: rgba(255, 255, 255, 0.5);
  }
}

.fb-nav__list {
  top: 10px;

  a:link,
  a:visited {
    text-decoration: none;
    color: white;
  }

  a:focus {
    border-color: $sbf-dark-green;
  }

  list-style: none;

  li {
    padding: 0 4px;
    display: inline-block;
    vertical-align: middle;
  }

  .icon {
    width: 24px;
    margin: 0 1px;
  }

  @media (min-width: $medium - 1) {
    .icon:hover {
      fill: #C9DDB4;
    }
  }

  @media (max-width: $medium - 1) {
    top: 0;
    padding: 24px 0;
    margin: 0;
    border-bottom: 1px solid #CED1D6;

    a:link,
    a:visited {
      color: $sbf-blue;
    }
  }
}

.fb-nav__list.donationNav {
  letter-spacing: 0.05em;
}

.list-nav {
  top: 50px;
  left: 0;
  text-align: left;
  border: 1px solid $sbf-palette-gray-300;
  box-shadow: 0 0 10px 0 rgba(53, 53, 53, 0.1);

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    display: block;
    color: #7A7A7A;
    border-bottom: 1px solid #EEE;
    font-size: 0.9em;
    padding: 0;
    transition: background-color 60ms linear;

    .icon {
      float: left;
      height: 16px;
      width: 16px;
      margin: 3px 8px 0 2px;

      &::before {
        font-size: 14px;
      }
    }
  }

  a:link,
  a:visited {
    padding: 8px;
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: $sbf-black;
    background: transparent;
    transition: color 50ms linear, background 50ms linear;
  }

  a:hover {
    background: $sbf-palette-gray-200;
    color: $sbf-palette-blue-500;
  }

  &.communicate-nav {
    left: -246px;
  }

  &.donation-nav {
    left: -246px;
  }

  &.settings-nav {
    left: -209px;
    width: 250px;

    a:link,
    a:visited {
      svg,
      svg:hover {
        fill: $sbf-palette-gray-500;
      }
    }
  }

  &.dashboard-nav {
    top: 42px;
    left: -150px;
    width: 250px;

    > ul > li:first-child {
      border-top: none;
    }

    > ul > li {
      border-top-width: 3px;
    }

    li {
      border-top: 1px solid #EEE;
      border-bottom: none;
    }

    // full screen menu at mobile nav size
    &.fullscreen {
      top: 0;
      left: 0;
      background-color: white;
      padding: 0 0 58px 0;
      width: auto;

      a:link,
      a:visited,
      .dashboard-nav__heading,
      .dashboard-nav__collapse summary {
        padding: 12px 16px;
      }
    }
  }

  .dashboard-nav__heading {
    font-size: 16px;
    color: $sbf-black;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    padding: 8px;
  }

  .dashboard-nav__collapse {
    details {
      position: relative;
      interpolate-size: allow-keywords;
      cursor: pointer;

      &::details-content {
        block-size: 0;
        overflow: hidden;
        transition: block-size 0.5s, content-visibility 0.5s;
        transition-behavior: allow-discrete;
      }

      &[open]::details-content {
        block-size: auto;
      }

      &:not([open]) .open,
      &[open] .closed {
        display: none;
      }
    }

    summary {
      padding: 8px;
      color: $sbf-palette-blue-500;
      font-weight: 500;
      font-size: 14px;
      background: $sbf-palette-gray-100;
      transition: background 50ms linear;

      &:hover {
        background: $sbf-palette-gray-200;
      }

      &::-webkit-details-marker {
        display: none;
      }

      .icon {
        float: none;
        height: 12px;
        width: 12px;
        top: 2px;
        transition: transform 0.3s ease-in-out;
        transform-origin: 6px 9px;

        &:hover {
          fill: currentColor;
        }
      }
    }

    details[open] summary .icon {
      transform: translateY(-50%) rotate(180deg);
    }

    li {
      a:link,
      a:visited {
        background: $sbf-palette-gray-100;
      }

      a:hover,
      a:focus {
        background: $sbf-palette-gray-200;
      }
    }
  }

  .dashboard-nav__entity::before {
    content: '\00B7\0020';
  }
}

.communicate-nav,
.donation-nav {
  width: 290px;

  li {
    overflow: hidden;

    &:hover {
      background-color: #EEE;
    }

    a:link,
    a:visited {
      padding: 12px 16px;
      font-size: 13px;
      line-height: 1;

      .donation-nav__amount {
        color: $sbf-blue;
        font-weight: bold;
      }
    }

    &.communicate-nav__more-actions,
    &.donation-nav__more-actions {
      &:hover {
        background-color: white;
      }

      padding: 10px 0;
      text-align: center;

      a:link,
      a:visited {
        display: inline;
        font-size: 12px;
        font-weight: bold;
        padding: 0 3px;
      }

      a:hover {
        color: lighten($sbf-blue, 10%);
      }
    }
  }
}

.overlay.donation-nav {
  a:link,
  a:visited {
    color: #777;
  }

  a.donation-nav__amount:link,
  a.donation-nav__amount:visited {
    color: $sbf-blue;
  }
}

.dashboard_nav_toggle {
  &.spinner-before::before {
    left: -17px;
    top: 6px;
    position: absolute;
  }
}

.communicate-nav__author,
.donation-nav__donor {
  font-weight: bold;
}

.donation-nav__date {
  font-size: 11px;
  color: #AAA;
  display: block;
  margin-bottom: 2px;
  font-weight: normal;
}

.donation-nav__content {
  line-height: 1.3;
}

.communicate-nav__subject {
  font-weight: 400;
}

.communicate-nav__preview {
  margin-top: 7px;
  display: block;
  color: #9F9F9F;
  font-size: 12px;
  height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$hamWidth: 32px;

.hamburger {
  display: none;
  font-size: 32px;
  cursor: pointer;
  width: $hamWidth;
  height: 20px;
  position: absolute;
  top: 23px;
  right: 16px;

  .line {
    display: block;
    background: white;
    width: $hamWidth;
    height: 4px;
    position: absolute;
    left: 0;
    transition: all 0.4s ease;
  }

  .newStuffBadge {
    top: -8px;
  }

  .line-1 {
    top: 0;
  }

  .line-2 {
    top: 50%;
  }

  .line-3 {
    top: 100%;
  }

  &.active {
    .line-1 {
      transform: translateY(10px) translateX(0) rotate(45deg);
    }

    .line-2 {
      opacity: 0;
    }

    .line-3 {
      transform: translateY(-10px) translateX(0) rotate(-45deg);
    }

    .newStuffBadge {
      opacity: 0;
    }
  }

  @media (max-width: $medium - 1) {
    display: block;
  }
}

@media (min-width: $medium) {
  .fb-nav__list {
    display: inline-block;
  }

  .profileNav {
    li {
      display: none;
    }
  }

  .donationNav {
    display: none;
  }
}

.donationNav li {
  font-family: $serifFamily;

  a:link,
  a:visited {
    color: $sbf-green;
    text-decoration: underline;
  }
}

.newStuffBadge {
  position: absolute;
  top: -1px;
  right: -5px;
  background: $sbf-red;
  text-indent: 0;
  color: white;
  text-align: center;
  background-clip: padding-box;
  font-size: 11px;
  font-weight: 900;
  line-height: normal;
  transition: opacity 0.4s ease;
  padding: 2px 4px;
  display: inline-block;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 4px 0;
}

/* styles for badge */

.new-donation-nav,
.doe-nav,
.doe-nav-1 {
  right: -7px;
  left: auto;
  width: 250px;
  z-index: 10000;

  &.dark {
    background: #F4EFEB;
    width: 275px;
    padding: 22px;
    border-radius: 10px;
    right: -8px;
    box-shadow: 0 8px 8px 0 rgb(53 53 53 / 30%);

    &::after {
      border-color: #F4EFEB #F4EFEB transparent transparent;
      background: inherit;
    }
  }

  h4 {
    font-size: 18px;
    margin: 0 0 8px 0;
    letter-spacing: normal;
  }

  p {
    font-family: $sansSerifFamily;
    margin: 0;
    font-size: 14px;
    letter-spacing: normal;
  }

  li:hover {
    background: #EEE;
  }

  .dashboard-nav__heading:hover {
    background: white;
  }

  ul:nth-child(n+2) .dashboard-nav__heading {
    border-top: 3px solid #EEE;
  }
}

.doe-nav.overlay--shown,
.new-donation--desktop.overlay.overlay--shown {
  display: none;

  @media (min-width: $medium) {
    display: block;
  }
}

.new-donation-nav {
  &.dark {
    right: -9px;
  }
}

@media (max-width: $medium - 1) {
  .no-mobile {
    display: none !important;
  }
}

.doe-nav-1 {
  top: 70px;
  right: 90px;
  width: 260px;

  &.dark {
    width: 260px;
    right: 90px;
  }
}

.doe-nav-1.overlay--shown,
.new-donation--mobile.overlay.overlay--shown {
  display: block;

  @media (min-width: $medium) {
    display: none;
  }
}

.new-donation--mobile.dark {
  top: 70px;
  right: 48px;
}

.doe-nav-toggle {
  margin-right: 1px;
}

.list-nav .doe-close:link,
.list-nav .doe-close:visited,
.list-nav .new-donation-close:link,
.list-nav .new-donation-close:visited {
  position: absolute;
  top: 0;
  right: 14px;
  color: $sbf-black;
  font-size: 18px;
  padding: 8px;
}

.new-donation-badge,
.doe-badge {
  background: none;
  box-shadow: none;
  height: 24px;
  width: 100%;
  line-height: 1.7;
  right: -11px;
  top: -4px;
  z-index: 1;

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 23px;
    color: $sbf-blue;
    z-index: -1;
  }
}

.login {
  text-decoration: none;

  &::after {
    content: '\25B6';
    transform: rotate(90deg);
    display: inline-block;
    margin: 0 0 0 8px;
  }
}

.logo-container {
  .logo-img {
    height: 35px;
    width: 227px;

    @media (min-width: $small) {
      height: 39px;
      width: 253px;
    }

    @media (min-width: $large) {
      height: 52px;
      width: 336px;
    }
  }
}

@media (max-width: $medium - 1) {
  .logo-container {
    transform-origin: left center;
    margin: 12px 0 4px 0;
    overflow: hidden;
  }
}

.frontend_nav {
  display: none;
  font-weight: normal;
  list-style: none;
  background: #CFDBE5;
  padding: 0;
  margin: 0;

  li {
    a {
      display: block;
      padding: 12px 0;
    }

    a:link,
    a:visited {
      color: $sbf-blue;
      font-family: $serifFamily;
    }
  }

  >li {
    padding: 0;
    border-bottom: 1px solid #CED1D6;
  }

  ul {
    list-style: none;
    font-weight: normal;
    margin: 0;
  }

  >li > ul {
    >li {
      & + li {
        >a {
          border-top: none;
        }
      }

      >a {
        font-size: smaller;
        padding: 12px 0;
        border: 1px solid darken(#CFDBE5, 15);
        background: darken(#CFDBE5, 5);
      }
    }
  }

  @media (max-width: $medium - 1) {
    display: block;
  }
}

.primary-nav.primary-nav {
  width: 100%;
  background: $sbf-green;
  max-width: none !important;
  min-height: 0;
  color: white;
  padding: 0;
  text-align: center;
  font-family: $serifFamily;
  font-size: 0.88em;
  border-top: 1px solid #44632E;
  box-shadow: inset rgba(255, 255, 255, 0.1) 0 1px 0 0;
  display: none;
  line-height: 1.35em;

  >nav {
    width: 100%;
    max-width: $x-large;
    margin: 0 auto;
  }

  a:link,
  a:visited {
    color: white;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    display: inline-block;
  }

  li ul {
    display: none;
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 177px;
    background-color: $sbf-gold;
    text-align: left;
    overflow: hidden;
    font-size: 15px;
    line-height: 18px;
    border-radius: 0 0 5px 5px;
    padding-top: 3px;
    padding-bottom: 2px;
    z-index: 1;

    @media (min-width: $primary-nav-bp) {
      /* Between 760 - 1019bp dropdown nav hard to select from. TP-28893 */
      top: 48px;
    }

    li {
      display: block;
    }

    a {
      display: block;
      font-weight: 500;
      padding: 10px 6px;

      &:hover {
        background: #C3AB7B;
      }

      @media (min-width: $large) {
        padding: 10px 20px;
      }
    }
  }

  .main-nav__main-link {
    padding: 10px 4px;
    font-weight: 500;

    @media (min-width: 1020px) {
      padding: 12px;
    }
  }

  .nav {
    >li {
      &::before {
        content: ' • ';
        color: #333;
      }

      &:first-child::before {
        display: none;
      }
    }

    li {
      &:hover {
        ul {
          display: block;
        }
      }
    }
  }

  @media (min-width: $medium) {
    display: block;
  }

  @media (min-width: $medium) {
    font-size: 1.15em;
  }
}

.snav__main-item {
  display: inline-block;
  padding: 0 10px;
  font-weight: 600;

  >a {
    padding: 10px 0;
  }

  &::after {
    content: '•';
    color: darken($sbf-green, 35%);
    position: relative;
    right: -12px;
    top: -2px;
    font-size: 0.6em;
  }

  &:last-child::after {
    content: '';
  }

  &:hover {
    &::before {
      content: '';
      height: 0;
      width: 0;
      border-bottom: 5px solid $sbf-gold;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      position: absolute;
      bottom: -2px;
      left: 50%;
      margin-left: -2px;
    }

    .snav__dropdown {
      display: block;
    }
  }
}

.snav__dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  text-align: left;
  background: $sbf-gold;
  font-size: 0.8em;
  width: 177px;
  border-top: 3px solid $sbf-gold;
  border-bottom: 3px solid $sbf-gold;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow: hidden;

  li a {
    display: block;
    padding: 8px 16px;
    font-weight: bold;

    &:hover {
      background: lighten($sbf-gold, 10%);
    }
  }
}

.old-header {
  >* {
    display: flex;
    align-items: center;
    padding: 10px 16px 0 16px;
    min-height: 58px;

    @media (min-width: $medium) {
      padding: 8px 24px 6px 24px;
    }
  }
}

.old-header__right-block {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 2;
  flex-wrap: wrap;
  top: -6px;
  pointer-events: none;
  -webkit-appearance: none;

  >* {
    pointer-events: auto;
  }

  .button {
    padding: 11px 20px;
    border-radius: 3px;
    font-size: 1em;
    line-height: 1.4;
    top: 4px;

    @media (min-width: $small) {
      top: 2px;
    }

    @media (min-width: $medium) {
      top: 0;
    }
  }

  .search_button {
    top: 14px;
    right: 16px;
    color: #518BB7;

    @media (min-width: 1020px) {
      top: 24px;
    }
  }

  .old-header__search {
    width: 255px;
    margin: 0 0 0 12px;
  }

  .search-term {
    color: #444;
    box-shadow: inset 0 1px 2px #CCC;
    background: white;
    font-size: 16px;
    -webkit-appearance: none;
    top: 0;
    padding: 9px 14px;
    border-radius: 4px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    background-clip: padding-box;
    width: 100%;

    @media (min-width: 1020px) {
      margin-top: 12px;
    }

    @media (min-width: $medium) {
      font-size: 13px;
    }
  }

  .old-header__nav {
    width: 100%;
    text-align: right;

    @media (min-width: 900px) {
      height: 34px;
      width: 150px;
      line-height: 1;
      padding: 0 12px;
      top: -7px;
    }

    @media (min-width: 1020px) {
      .old-header__links:nth-child(even)::after {
        display: none;
      }
    }
  }

  .old-header__link-wrap {
    display: inline;

    @media (min-width: 1020px) {
      display: block;
    }
  }

  .old-header__links--loggedout {
    line-height: 1.5;

    @media (min-width: $large) {
      line-height: 2.5;
    }
  }

  .old-header__links {
    color: white;
    font-size: 12px;
    font-weight: bold;
    margin: 0 2px;

    &::after {
      content: '•';
      position: relative;
      right: -4px;
      font-weight: normal;
    }

    &:last-child::after {
      display: none;
    }
  }

  @media (min-width: 1020px) {
    max-width: none;
  }
}

.old-header__menu-link,
.old-footer__nav {
  display: none;
}

@media (max-width: $medium - 1) {
  .old-header {
    border-bottom: 3px solid $sbf-gold;
    margin: 0 0 40px 0;
    padding: 0 0 4px 0;
  }

  .logo-container {
    margin: 0;
  }

  .old-header__nav {
    display: none;
  }

  .icon-arrow.icon-arrow {
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 6px;
  }

  .old-header__right-block {
    width: 100%;
    max-width: none;

    .old-header__search {
      background: #E8F0F5;
      position: absolute;
      top: 59px;
      left: -16px;
      margin: 0;
      width: calc(100% + 32px);
      padding-left: 74px;
      overflow: hidden;
    }

    .search-term {
      border: none;
      border-radius: 0;
      background: #E8F0F5;
      padding: 14px;
      box-shadow: none;
    }

    .search-button {
      top: 12px;
      right: 30px;
    }
  }

  .old-header__menu-link {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 18px 28px 16px 16px;
    border-right: 1px solid #D1E0EC;
    text-transform: uppercase;
    font-weight: bold;
    color: $sbf-blue;
    font-size: 0.6em;
    z-index: 10;

    &:hover,
    &:focus {
      border-color: #D1E0EC;
    }
  }

  .old-footer__nav {
    display: block;
    width: 100%;
    background: white;

    ul {
      margin-bottom: 0;
    }

    .subnav > li {
      background: #E8F0F5;
      padding: 12px;
      border-top: 1px solid #D1E0EC;
      font-size: 1em;
      line-height: 1.35em;
      font-weight: 500;
      font-family: $serifFamily;
    }

    a:link,
    a:visited {
      border-bottom: none;
      color: $sbf-blue;
    }

    .old-footer__links:link,
    .old-footer__links:visited {
      color: $sbf-blue;
    }

    .icon-arrow.icon-arrow {
      top: 7px;
    }

    ul > li > ul {
      list-style: none;
      padding: 8px 28px;

      > li {
        border: none;

        a {
          display: block;
          padding: 5px;

          &:hover {
            background-color: rgba(white, 0.2);
            color: #0C85CD;
          }
        }
      }
    }
  }

  .old-footer__links {
    font-size: 1em;
    line-height: 1.35em;
    font-weight: bold;
    padding: 8px 0 8px 16px;
    display: inline-block;
    color: $sbf-blue;

    .icon-arrow {
      transform: rotate(180deg);
      transform-origin: center;
      display: inline-block;
    }
  }

  .old-footer__links.emails {
    padding-left: 0;

    &::before {
      content: ' \00b7\ ';

      /* middot */
    }
  }

  .to-top {
    float: right;
    padding-right: 32px;
    text-transform: uppercase;
  }
}

@media (max-width: $large - 1) {
  .old-header .logo-container {
    position: absolute;
  }
}
