@import "~sbf-design-system/scss/base";

// Overrides on default styles
#termsfeed-pc1-notice-banner {
  .termsfeed-pc1-notice-banner-layout {
    box-sizing: border-box;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 3px 3px 0 0;

    @media (min-width: $large) {
      max-width: 50%;
    }
  }

  .termsfeed-pc1-notice-banner-inner {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 10px 20px;
  }

  .termsfeed-pc1-notice-banner-footer {
    padding: 0;
  }

  .termsfeed-pc1-notice-banner-content {
    @include sbf-body('default-sans');

    margin: 0 0 12px 0;
    color: $sbf-white;
  }

  .termsfeed-pc1-notice-banner-content-weusecookies-purposes-text {
    display: block;
    margin: 0 0 4px 0;
  }

  .termsfeed-pc1-notice-banner-footer-savepreferences-button {
    margin: 0 6px 0 0;
  }
}

#termsfeed-pc1-preferences-center {
  font-family: $sansSerifFamily;
  font-weight: 600;

  .termsfeed-pc1-preferences-center-footer-rejectall-button {
    @media (min-width: 993px) {
      margin: 0 6px;
    }
  }

  .termsfeed-pc1-preferences-center-footer-saveselection-button {
    margin: 0 6px 0 0;
  }
}

#termsfeed-pc1-notice-banner,
#termsfeed-pc1-preferences-center {
  .termsfeed-pc1-notice-banner-footer-acceptall-button,
  .termsfeed-pc1-notice-banner-footer-rejectall-button,
  .termsfeed-pc1-preferences-center-footer-acceptall-button,
  .termsfeed-pc1-preferences-center-footer-rejectall-button,
  .termsfeed-pc1-preferences-center-footer-saveselection-button,
  .termsfeed-pc1-notice-banner-footer-savepreferences-button {
    @include sbf-button($btnType: 'sbf-blue', $btnSize: 'small');
  }

  .termsfeed-pc1-notice-banner-footer-close-button,
  .termsfeed-pc1-preferences-center-footer-close-button {
    @include sbf-button($btnType: 'sbf-green', $btnSize: 'small');
  }

  a {
    @include link($sbf-white);
  }
}

.termsfeed-pc1-ec-wrapper {
  display: none !important;

  &.termsfeed-pc1-visible {
    display: none !important;
  }
}
