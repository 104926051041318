@mixin spinner($closeness: 8px, $length: 10, $thickness: 3px, $color: #555) {
  $sh: ();
  $shadow: ();
  $w: $closeness;
  $n: $length;
  $k: floor($n*0.75);
  $f: floor($w*0.75);

  // Calculate box-shadow.
  @for $j from 0 through $length {
    $sh: $sh, 0 $j+($w) $color, $j+($w) 0 lighten($color, 30%), 0 (-$j)+(-$w) lighten($color, 50%), (-$j)+(-$w) 0 lighten($color, 62%);
  }

  @for $i from 0 through $k {
    $shadow: $sh, $shadow, $i+($f) $i+($f) lighten($color, 20%), (-$i)+(-$f) (-$i)+(-$f) lighten($color, 60%), $i+($f) (-$i)+(-$f) lighten($color, 40%), (-$i)+(-$f) $i+($f) lighten($color, 65%);
  }

  // Implements rules.
  animation: spin 1s infinite steps(8);
  transform-origin: 50% 50%;
  width: $thickness;
  height: $thickness;
  box-shadow: $shadow;

  @keyframes spin {
    to { transform: rotate(1turn); }
  }
}
